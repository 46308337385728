import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const CircularLoading = ({ height }) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

CircularLoading.propTypes = {
  height: PropTypes.number.isRequired,
};

export default CircularLoading;
